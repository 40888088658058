import Vue from 'vue'
import App from './page/App.vue'
import veform from 'veform'

Vue.config.productionTip = false

import "./page/style.scss"

Vue.use(veform)

new Vue({
  render: h => h(App),
}).$mount('#app')
