import axios from 'axios'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import { auth, envelopes } from 'libvf'


export default {
  name: "app",
  components: {
	Datepicker
  },
  data() {
    return {
			preview: false,
			ud: {
				year_ending: new Date(),
				company_name: null,
				auditor: null,
				lang: "sv_SE"
			},
			language: "sv",
			languages: lang,
			exceptions: [],
			envelope: null,
			loading: true,
			errorText: null,
			errorContinue: 'verifiedLink'
		}
	},
	methods: {

		validateForm(form) {
			return this.$refs[form].validate()
		},
		showPreview() {
			if(!this.validateForm("input")) {
				return
			}
			this.preview = true	
		},
		showInput(){
			this.preview = false
		},
		dateFormat(){
			return this.ud.year_ending.toISOString().slice(0, 10)
		},
		async submit() {
			this.ud._isForwarded = true
			this.ud.year_ending_str = this.dateFormat()
			this.ud.fileName = "Uttalande från företagsledningen_"+this.ud.company_name+"_"+this.ud.year_ending_str	
			this.loading = true
			try{
				// Set template data
				await this.envelope.firstTemplate().setUserData(this.ud)
				window.close()

			}catch(e){
				this.exceptions.push(e)
				this.errorText = 'cannotSave'
				console.log(e)		
			}
			this.loading = false

		}
	},

	async created() {

		// Set language to use
		this.setIso(this.ud.lang)

		const [translations] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/finnhammars-uttalande/translations')
		])

		// This combines veform's default translations with the ones from sheets
		// duplicates are overwritten by the sheet
		this.mergeLocale(translations.data)

		this.ud.translations = translations.data

		this.mergeLocale({
			"validation.required": {
				en_EN: "Cannot be empty",
				nb_NO: "Påkrevd",
				sv_SE: "Obligatorisk"
			}
		})
		try {
			// Authenticate libvf
			await auth.useTokenFromUrl()
			this.envelope = await envelopes.getFromUrl()
			//TODO: check if the envelope is published/aborted
			let data = await this.envelope.firstTemplate().getUserData();
			if(data){
				this.ud.company_name = data.company_name
				this.ud.auditor = data.auditor
				let date = new Date(data.year_ending)
				this.ud.year_ending = date	
			}
			this.loading = false
		  }
		  catch (e) {
			this.loading = false
			this.exceptions.push(e)
			this.errorText = 'noToken'
			console.log(e)
		  }
	  

	}
}